/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Anchor,
  Box,
  Burger,
  Flex,
  Group,
  Stack,
  Text,
  Title
} from "@mantine/core";
import { useViewportSize, useWindowScroll } from "@mantine/hooks";
import {
  IconBrandGithub,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconMail,
} from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
const HEADER_PADDING = 100;



export default function Header() {
  const [scroll, scrollTo] = useWindowScroll();
  const [active, setActive] = useState(0);
  const { width } = useViewportSize();
  const [menu, setMenu] = useState(false);
  const language = useContext(LanguageContext);
  const hoverGlowStyle = {
    color: "white",
    transition: "0.3s",
    "&:hover": {
      textShadow:
        "-0.2px -0.2px 0 #FFFFFF, 0.2px -0.2px 0 #FFFFFF, -0.2px 0.2px 0 #FFFFFF, 0.2px 0.2px 0 #FFFFFF;",
    },
  }
  
  const style = {
    ...hoverGlowStyle,
    paddingLeft: 5,
    paddingRight: 5,
   
  };

  const langAnchors = () => (
    <>
      <Anchor onClick={()=>{language.setLanguage("tr")}} sx={hoverGlowStyle} color="white" underline={false}>
       {language.languageCode === "tr" ?<u>TR</u> : "TR"}
      </Anchor>
      <Anchor onClick={()=>{language.setLanguage("en")}} sx={hoverGlowStyle} color="white" underline={false}>
      {language.languageCode === "en" ?<u>EN</u> : "EN"}
      </Anchor>
    </>
  );

  const anchorList = () => (
    <>
      <Anchor
        sx={style}
        underline={false}
        onClick={() => {
          goTo("about");
        }}
        color="white"
      >
        {active === 1 ? <u>{language.strings.headerAbout}</u> : <Text>{language.strings.headerAbout}</Text>}
      </Anchor>
      <Anchor
        sx={style}
        underline={false}
        onClick={() => {
          goTo("experiences");
        }}
        color="white"
      >
        {active === 2 ? <u>{language.strings.headerExperiences}</u> :<Text> {language.strings.headerExperiences}</Text>}
      </Anchor>
      <Anchor
        sx={style}
        underline={false}
        onClick={() => {
          goTo("skills");
        }}
        color="white"
      >
        {active === 3 ? <u>{language.strings.headerSkills}</u> :<Text> {language.strings.headerSkills}</Text>}
      </Anchor>
      <Anchor
        sx={style}
        underline={false}
        onClick={() => {
          goTo("projects");
        }}
        color="white"
      >
        {active === 4 ? <u>{language.strings.headerProjects}</u> :<Text> {language.strings.headerProjects}</Text>}
      </Anchor>
    </>
  );

  const socialLinkList = () => (
    <Group spacing={10}>
      <ActionIcon
        component="a"
        target="_blank"
        href={"https://instagram.com/tscburak"}
        size={"md"}
        p={3}
        sx={{
          backgroundColor: "black",
          color: "gray",
          borderColor: "gray",
          transition: "0.3s",
          "&:hover": {
            backgroundColor: "#d62976",
            color: "white",
            borderColor: "#962fbf",
          },
        }}
        radius={100}
      >
        <IconBrandInstagram />
      </ActionIcon>
      <ActionIcon
        component="a"
        target="_blank"
        href={"https://github.com/tscburak"}
        size={"md"}
        p={3}
        sx={{
          backgroundColor: "black",
          color: "gray",
          borderColor: "gray",
          transition: "0.3s",
          "&:hover": {
            backgroundColor: "#white",
            color: "black",
            borderColor: "white",
          },
        }}
        radius={100}
      >
        <IconBrandGithub size={26.4} />
      </ActionIcon>
      <ActionIcon
        component="a"
        target="_blank"
        href={"https://linkedin.com/in/tscburak"}
        size={"md"}
        p={3}
        sx={{
          backgroundColor: "black",
          color: "gray",
          borderColor: "gray",
          transition: "0.3s",
          "&:hover": {
            backgroundColor: "#0e76a8",
            color: "white",
            borderColor: "#0e76a8",
          },
        }}
        radius={100}
      >
        <IconBrandLinkedin size={26.4} />
      </ActionIcon>
      <ActionIcon
        component="a"
        target="_blank"
        href={"mailto:tscburak@outlook.com"}
        size={"md"}
        p={3}
        sx={{
          height: 28,
          backgroundColor: "black",
          color: "gray",
          borderColor: "gray",
          transition: "0.3s",
          "&:hover": {
            backgroundColor: "#0072c6",
            color: "white",
            borderColor: "#0072c6",
          },
        }}
        radius={100}
      >
        <IconMail size={26.4} />
      </ActionIcon>
    </Group>
  );
  const listenScrollEvent = () => {
    const headerHeight =
      window.document.getElementById("header")!.clientHeight + 10;

    const abouty =
      window.document.getElementById("about")!.getBoundingClientRect().y -
      headerHeight;
    const experiencesy =
      window.document.getElementById("experiences")!.getBoundingClientRect().y -
      headerHeight;
    const skillsy =
      window.document.getElementById("skills")!.getBoundingClientRect().y -
      headerHeight;
    const projectsy =
      window.document.getElementById("projects")!.getBoundingClientRect().y -
      headerHeight;

    if (scroll.y < abouty) {
      setActive(0);
    } else if (scroll.y >= abouty && scroll.y < experiencesy) {
      setActive(1);
    } else if (scroll.y >= experiencesy && scroll.y < skillsy) {
      setActive(2);
    } else if (scroll.y >= skillsy && scroll.y < projectsy) {
      setActive(3);
    } else if (scroll.y >= projectsy) {
      setActive(4);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  function goTo(id: string) {
    const rect = window.document.getElementById(id)?.getBoundingClientRect();
    const temp = scroll.y + (rect?.y || 0) - HEADER_PADDING;
    scrollTo({ y: temp + 5 });
  }

  return (
    <Box
      id="header"
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 1,
        display: "block",
        width: "100%",
      }}
    >
      <Flex
        p={"sm"}
        sx={{
          backgroundColor: "black",
          // boxShadow:"white 0px 0px 0px 0.1px ",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Flex
          sx={{ position: "absolute", left: "8vw" }}
          gap={"sm"}
          direction={"row"}
        >
          {width > 1200 ? (
            <>{anchorList()}</>
          ) : (
            <Burger
              opened={menu}
              onClick={() => {
                const temp = menu;
                setMenu(!temp);
              }}
              color="white"
            ></Burger>
          )}
        </Flex>
        <Stack
          spacing={1}
          sx={{ float: "right", display: "flex", justifyContent: "center" }}
        >
          <Title
            order={2}
            variant="gradient"
            ta={"center"}
            gradient={{ from: "whitesmoke", to: "gray", deg: 180 }}
            color="white"
          >
            tscburak.dev
          </Title>
            <Box display={width>=600 ? "flex" : "none"} sx={{ justifyContent: "center", display: "flex" }}>
              {socialLinkList()}
            </Box>
        </Stack>
        <Group spacing={8} sx={{justifyContent:"center", position:"absolute", right:"8vw"}} display={width >= 600 ? "flex" : "none"}>{langAnchors()}</Group>
      </Flex>
      <Stack
        display={menu && width <= 1200 ? "flex" : "none"}
        sx={{backgroundColor: "black", paddingBottom: 20 }}
      >
        <Stack pt={15} pl={"8vw"}> {anchorList()}</Stack>
        <Box sx={{justifyContent:"center"}} display={width < 600 ? "flex" : "none"}> {socialLinkList()}</Box>
        <Group sx={{justifyContent:"center"}} display={width < 600 ? "flex" : "none"}>{langAnchors()}</Group>
      </Stack>
    </Box>
  );
}
